import React, { useEffect, useState, useRef } from "react";
import { Link, graphql } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import { Scrollbar, Autoplay, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import {
  LayoutSl,
  SEO2,
  CBreadCrumb,
  AssetImage,
  LWrap2,
  RecruitNews,
  Vertical,
  CImgCard,
  WordPic,
  WordFull,
  ContainerCrumbs,
  InterviewPic,
  WordPicWords,
  CInterviewJumbotron,
  CQuoteBox,
  CInterviewMedia,
  CSectTitle,
  CSchedule,
  CStars,
  CLabelCard,
  CBtnList,
} from "../../../../components/_index";
import "../../../../assets/_sass/page/recruit/common.scss";
import { title } from "process";
import "../../../../assets/_sass/page/recruit/interview.scss";
import "../../../../assets/_sass/page/recruit/article01.scss";
import { useMobile } from "../../../../utils/use-series";

const SubPage = ({ data }: { data: any }) => {
  const isSp = useMobile();
  const frontmatter = data?.markdownRemark?.frontmatter;

  const verticalData = {
    title: "西田明仁",
    content: "Akihito Nishida",
    cla_left: "works_big_left",
    cla_right: "works_small_right",
    exClass: "container_left_No1",
    exClassWord: "container_left_sp no-copy",
  };

  const verticalDataTime = {
    title: " １日のスケジュール",
    content: "",
    cla_left: "works_big_left",
    cla_right: "works_small_right",
  };
  const crumbsData = [
    {
      title: "Top",
      src: "/recruit/",
    },
    {
      title: "社員インタビュー",
      src: "/recruit/interview/",
    },
    {
      title: "西田明仁",
      src: "/recruit/interview/article03/",
      exClass:"no-copy",
    },
  ];
  const interviewFullData1 = {
    title: "入社を決めた理由をお聞かせください",
    paragraph: [
      {
        title:
          "学生時代にアルバイトをしている頃から「接客次第でもっとお客さまに満足してもらえるようになるのでは」と接客の質を追求するのが楽しく、自然と接客業を目指すようになりホテルへ就職しました。ホテルを選んだのは「とにかく接客のプロになりたい」という、漠然とした理由からです。",
      },
      {
        title:
          "新卒で入社したホテルではずっとレストラン勤務であったため、他の業務に携わった経験はありません。しかし、好奇心旺盛な性格もあり、他のさまざまな業務にも関わっていきたいという気持ちをずっと持っていました。",
      },
      {
        title:
          "そのようなとき、知人より当ホテルの紹介を受けて話を聞くことになります。正直なところ、当ホテルについては、何となく「昔ながらの日本のホテルグループなのかな」くらいの情報しか持っていませんでした。しかし、話を聞く中で多彩なホテルコンセプトや新しいブランドへの挑戦など、非常にアグレッシブに成長を続けていると感じました。",
      },
      {
        title:
          "ふと立ち止まって自分の将来を考えたとき、「さまざまな可能性を考えられるように選択肢を増やしたい。自分の考え方と好奇心を満たしてくれるのは、当ホテルなのではないか」と強く感じ、当ホテルへの転職を決めたのが入社の経緯となります。",
      },
    ],
  };

  const interviewFullData2 = {
    title: "職場の雰囲気はいかがでしょうか",
    paragraph: [
      {
        title:
          "当ホテルは、40代の総支配人を筆頭に、スタッフの年齢層が20～30歳前半中心と比較的若いのが特徴です。全体的に年齢が近いこともあり、和気あいあいと風通しのよい組織になっていると思います。",
      },
      {
        title:
          "部下や後輩は各個人でうまくコミュニケーションが取れており、お互いを尊重しあっている空気感がありますね。上司（総支配人）は仕事に対してはリアリストで、数字を大事にする人です。反面、スタッフに対しては接しやすい雰囲気作りを意識しているので、私もよくふざけて冗談を言いあっています。",
      },
      {
        title:
          "こういった雰囲気は、職場改善やアイデアを提案しやすいところにもつながっています。スタッフからの提案をもとにして作ったコンセプトルームや宿泊プランを販売しているのですが、これは「こんな部屋があったらいいのにね」というスタッフ同士の会話をきっかけにして始まった企画です。上司部下関係なく、何気ない会話ができる当ホテルの雰囲気があってこそ、自由な発想が生まれたのだなと感じています。",
      },
    ],
  };

  const interviewFullData4 = {
    title:
      "ホテルで働く魅力や、当社を目指す方々へのメッセージをお聞かせください",
    paragraph: [
      {
        title:
          "ホテルに求められる接客は、ファストフード店やコンビニエンスストアとは違い、かしこまったサービスで利用者を出迎えてくれると考える方が多いのではと思います。当ホテルはカジュアルな雰囲気ではありますが、いわゆる「ホテル」としてイメージされる接客サービスや言葉づかいは求められるものです。",
      },
    ],
  };
  const picData1 = {
    img: {
      src: "/assets/images/recruit/interview/article03/interview_article03_02.png",
      alt: "",
    },
    title: "現在の業務内容を教えてください",
    paragraph: [
      {
        title:
          "『ザ ロイヤルパーク キャンバス 大阪北浜』のフロントチームに、マネージャーとして所属しています。大阪北浜のフロントチームの主な業務は、お客さまに対してのご宿泊のご案内と、併設されているカフェラウンジの営業です。『その街ならではのおもてなしを提供する』といった当社の方針にならって、当ホテルでは近隣で活動するアーティストやパフォーマーを呼んで、イベントを開催することもあります。カフェラウンジにはDJブースもあるんですよ！",
      },
      {
        title:
          "私は現場責任者として、チームが円滑に業務を進められるようまとめる立ち位置で仕事をしています。基本的に私個人で行う業務はほとんどなく、チーム全体で話し合って進めています。",
      },
      {
        title:
          "お客さまをお迎えする上で大切にしているのは、アイコンタクトです。接客業においては、第一印象は非常に重要な要素だと思っています。ふとした言動から反感やネガティブな印象を持たれないよう、「私たちはあなたを歓迎しています」という気持ちを、お客さまの目をしっかりと見て伝えることを大事にしています。",
      },
    ],
    subcontent: "",
    // position: true,
    positionSp: true,
  };

  const picData3 = {
    img: {
      src: "/assets/images/recruit/interview/article03/interview_article03_03.png",
      alt: "",
    },
    title: "仕事のやりがいや達成感はどのようなところに感じていますか",
    paragraph: [
      {
        title:
          "自分と同じように、毎年多くのスタッフが入社してきます。私もマネージャーという立場上、新人教育に携わるケースが数多くあります。そのような中、私がトレーニングや指導を担当したスタッフが、1年2年と成長している姿を目の当たりにしたときはうれしく、達成感を得られました。これまでに接客経験は一度もないと言っていたスタッフが、テキパキと仕事をこなしている姿を見ると、胸に熱いものがこみ上げてきますね。学校の先生になったらきっとこんな気持ちになるのかな、と。",
      },
      {
        title:
          "当ホテルに来て、前職では経験できなかったフロントの仕事に就きました。さらに、チーム内のマネジメント業務を担う立場の一員として携われた経験が、これからの私の選択肢をより広いものとしてくれているとモチベーションの高まりを感じています。これからも未知の部分に恐れず、新しいことや知らないことにどんどん触れられる経験を積みたいと思っています。",
      },
    ],
    subcontent: "",
    position: true,
  };
  const picData4 = {
    img: {
      src: "/assets/images/recruit/interview/article03/interview_article03_04.png",
      alt: "",
    },
    title: "業務上で気をつけているのはどのようなところでしょうか",
    paragraph: [
      {
        title:
          "業務上で大切だと思っているのは「業務引継ぎ」です。私たちフロントは、基本的にシフト制の勤務体系です。そのため、毎週同じ日、毎日同じ時間で働く機会は多くありません。自分が体験したこと、業務の中で感じたことを、できる限り鮮明な形で全員に伝えるのは大事だと思っています。",
      },
      {
        title:
          "自分の仕事に集中しすぎず全体を見ることも、非常に気をつかっている点です。接客中に別のお客さまが来館されて自分以外気づいていないというケースがあった場合、口頭で伝えられれば問題ないのですが、伝えられないタイミングもあるものです。そのような際に、スタッフ同士でアイコンタクトを取って、誰かにお客さまの対応を促せるといった力は必要だと考えています。",
      },
      {
        title:
          "お客さまの目線で考えると、対応のたびに人が変わって同じ内容を繰り返し確認されたり、質問したいのに時間がかかったりすると、よい気分はしないでしょう。スタッフ全員が一丸となって「1人のスタッフ」となり、誰にでも同じ対応ができるようにしたいと常々考えています。",
      },
      {
        title:
          "あとは、シンプルに笑顔でいることですね。1人が笑顔でいると、周囲も笑顔になる。そういった環境で働きたいですし、お客さまにもよい雰囲気を感じてもらいたいと思っています。",
      },
    ],
    subcontent: "",
    // position: true,
    positionSp: true,
  };

  const picData5 = {
    img: {
      src: "/assets/images/recruit/interview/article03/interview_article03_05.png",
      alt: "",
    },
    title: "",
    paragraph: [
      {
        title:
          "立つ姿勢や歩き方といった接客での振る舞いなど、ホテルに勤務して身につけられる技術は数多くありますので、接客を磨きたい方にはうってつけの職場ではないかと思います。",
      },
      {
        title:
          "当ホテルは「ライフスタイルホテル」というカテゴリーとなり、観光やワーケーションなど、お客さまそれぞれの利用目的に寄り添ったサービスを提案するスタイルのホテルです。私たちは「大阪で一番のライフスタイルホテルになる」をチーム共通の目標に掲げ、、さまざまなイベントやプランのアイデアを日々考えています。楽しいイベントを提案してみたいと考える方、新しいホテルを作りあげてみたいという意欲のある方、当ホテルでできることはたくさんあります。ぜひ私たちと一緒に働きましょう。",
      },
    ],
    subcontent: "",
    position: true,
  };

  const carouselData = [
    {
      img: {
        src: "/assets/images/recruit/interview/interview.png",
        alt: "",
      },
      title: "ザ ロイヤルパークホテル アイコニック 東京汐留 フロントチーム",
      content: (
        <>
          「人と関わる」を極めるためアニメ制作の道からホテルマンに軌道修正。
          <br />
          入社10年目を前に改めて「意思疎通や連携を大事にする会社」と実感
        </>
      ),

      end_title: "2015年 新卒入社",
      jumpUrl: "/recruit/interview/article01/",
    },
    {
      img: {
        src: "/assets/images/recruit/interview/interview2.png",
        alt: "",
      },
      title: "丸ノ内ホテル 料飲　(レストラン ポム・ダダン) ",
      content: (
        <>
          お客さまの大切な時間を共有できるのがレストラン部門の醍醐味。
          <br />
          価値ある取り組みを紹介する「真心感動ボード」も仕事への意欲に
        </>
      ),
      end_title: "2020年 新卒入社",
      jumpUrl: "/recruit/interview/article02/",
    },
    {
      img: {
        src: "/assets/images/recruit/interview/interview3.png",
        alt: "",
      },
      title: "ザ ロイヤルパーク キャンバス 大阪北浜 フロントチーム",
      content: (
        <>
          「さまざまな接客を経験したい」と、これまで経験のないフロント業務ができる当社へ。ライフスタイルホテルの最高峰を目指し、若いスタッフの意見を採り入れてイベントを実施する
        </>
      ),

      end_title: "2019年 キャリア入社",
      jumpUrl: "/recruit/interview/article03/",
    },
    {
      img: {
        src: "/assets/images/recruit/interview/interview4.png",
        alt: "",
      },
      title: "ザ ロイヤルパークホテル アイコニック 大阪御堂筋 フロントチーム",
      content: (
        <>
          学生時代に宿泊したホテルのサービスに感動し、ホテル業界で働きたいと当社へ就職。お客さまやスタッフとのコミュニケーションを高め、おもてなしの最高峰を極める
        </>
      ),

      end_title: "2020年 新卒入社",
      jumpUrl: "/recruit/interview/article04/",
    },

    {
      img: {
        src: "/assets/images/recruit/interview/interview5.png",
        alt: "",
      },
      title:
        "ロイヤルパークホテル（東京・日本橋）調理部 洋食調理二課 バンケットコールド調理",
      content: (
        <>
          作る料理に一切の妥協をしない。
          <br />
          コンクールにも挑戦し、自分の武器を増やしながら成長を重ねる毎日
        </>
      ),
      end_title: "2020年 新卒入社",
      jumpUrl: "/recruit/interview/article05/",
    },
    {
      img: {
        src: "/assets/images/recruit/interview/interview6.png",
        alt: "",
      },
      title: "仙台ロイヤルパークホテル 婚礼",
      content: (
        <>
          「不採用でもあきらめない」
          <br />
          子どもの頃の憧れを形に掴んだキャリア
        </>
      ),

      end_title: "2020年 キャリア入社",
      jumpUrl: "/recruit/interview/article06/",
    },
  ];

  return (
    <LayoutSl isKv={false}>
      <SEO2
        title={frontmatter?.title}
        description={frontmatter?.description}
        isTop={false}
      />
      <section className="l_sect04 l_sect04_sp recruit-font">
        <LWrap2>
          <div className="new_main">
            <ContainerCrumbs data={crumbsData} />
            <div className="container_main container_main_interview ">
              <Vertical data={verticalData} />
              <div className="container_right interview-container-right">
                <AssetImage
                  src="/assets/images/recruit/interview/article03/interview_article03.png"
                  alt=""
                  loading="lazy"
                  wrapClassName="article-pc"
                />

                {isSp && (
                  <AssetImage
                    src="/assets/images/recruit/interview/article03/interview-article03-media.png"
                    alt=""
                    loading="lazy"
                    wrapClassName="article-media"
                  />
                )}
                <div className="interview_title">
                  <p className="m_title_message m_title_message-media">
                    「さまざまな接客を経験したい」と、
                    <br />
                    これまで経験のないフロント業務がで
                    <br className="u_sp" />
                    きる当社へ。
                    <br />
                    ライフスタイルホテルの最高峰を目指
                    <br className="u_sp" />
                    し、
                    <br className="u_pc" />
                    若いスタッフの意見を採り入れて
                    <br className="u_sp" />
                    イベントを実施する
                  </p>
                </div>
                <div className="interview_subtitle">
                  <p className="m_subtitle_message m_title_subtitle-media">
                    2019年 キャリア入社
                    <br />ザ ロイヤルパーク キャンバス 大阪北浜 フロントチーム
                  </p>
                </div>
              </div>
            </div>
          </div>
        </LWrap2>
      </section>
      <section className="l_sect04 l_sect04_sp recruit-font">
        <LWrap2>
          <div className="person-article-div">
            <div className="new_main">
              <WordFull
                data={interviewFullData1}
                exClass="container_main_message_full2"
              />
              <WordPic
                data={picData1}
                exClass="container_message_picture_same container_main_message_bisect2"
                exRightClass="article03-p1-p"
              />
              <WordFull
                data={interviewFullData2}
                exClass="container_main_message_full2"
              />
              <WordPic
                data={picData3}
                exRightClass="right-pic-media-left"
                exClass="container_main_message_bisect2"
              />
              {/* <WordFull data={interviewFullData3} /> */}
              <WordPic
                data={picData4}
                exClass="reverse-container_main_message_bisect container_main_message_bisect2"
                exRightClass="small-right-pic article03-p4-p  bisect_right2"
              />

              <WordFull
                data={interviewFullData4}
                exClass="container_main_message_full2"
              />
              <WordPic
                data={picData5}
                exClass="container_message_picture_same container_main_message_bisect2"
                exRightClass="article03-p5-p"
              />
            </div>
          </div>
        </LWrap2>
      </section>
      <section className="l_sect04  u_grey recruit-font">
        <LWrap2>
          <div className="new_main u_grey">
            <div className="container_main container_main_time container_main_time-media">
              <Vertical data={verticalDataTime} />
              <div className="interview_a1_mythBox article01-mythBox">
                <div className="interview_a1_timebox-left">
                  <p className="interview_a1_timebox-title">日勤</p>
                  <div className="interview_a1_timeline-item">
                    <i className="interview_a1_timeline-axis">
                      <AssetImage
                        src="/assets/images/recruit/interview/time.svg"
                        alt=""
                        loading="lazy"
                      />
                    </i>
                    <div className="interview_a1_timeline-content text interview_a1_timeline-content-media">
                      <div className="interview_a1_timeline-detail">08:30</div>
                      <div className="interview_a1_timeline-title">
                        出勤、引継ぎ <br />
                        チェックアウト業務
                      </div>
                    </div>
                  </div>
                  <div className="interview_a1_timeline-item">
                    <i className="interview_a1_timeline-axis">
                      <AssetImage
                        src="/assets/images/recruit/interview/time02.svg"
                        alt=""
                        loading="lazy"
                      />
                    </i>
                    <div className="interview_a1_timeline-content text interview_a1_timeline-content-media">
                      <div className="interview_a1_timeline-detail">12:00</div>
                      <div className="interview_a1_timeline-title">
                        午前中の会計確認
                      </div>
                    </div>
                  </div>
                  <div className="interview_a1_timeline-item">
                    <i className="interview_a1_timeline-axis">
                      <AssetImage
                        src="/assets/images/recruit/interview/time03.svg"
                        alt=""
                        loading="lazy"
                      />
                    </i>
                    <div className="interview_a1_timeline-content text interview_a1_timeline-content-media">
                      <div className="interview_a1_timeline-detail">13:00</div>
                      <div className="interview_a1_timeline-title">休憩</div>
                    </div>
                  </div>
                  <div className="interview_a1_timeline-item">
                    <i className="interview_a1_timeline-axis">
                      <AssetImage
                        src="/assets/images/recruit/interview/time04.svg"
                        alt=""
                        loading="lazy"
                      />
                    </i>
                    <div className="interview_a1_timeline-content text interview_a1_timeline-content-media">
                      <div className="interview_a1_timeline-detail">14:00</div>
                      <div className="interview_a1_timeline-title">
                        事務作業
                      </div>
                    </div>
                  </div>
                  <div className="interview_a1_timeline-item">
                    <i className="interview_a1_timeline-axis">
                      <AssetImage
                        src="/assets/images/recruit/interview/time05.svg"
                        alt=""
                        loading="lazy"
                      />
                    </i>
                    <div className="interview_a1_timeline-content text interview_a1_timeline-content-media">
                      <div className="interview_a1_timeline-detail">15:00</div>
                      <div className="interview_a1_timeline-title">
                        チェックイン業務
                      </div>
                    </div>
                  </div>
                  <div className="interview_a1_timeline-item">
                    <i className="interview_a1_timeline-axis">
                      <AssetImage
                        src="/assets/images/recruit/interview/time05.svg"
                        alt=""
                        loading="lazy"
                      />
                    </i>
                    <div className="interview_a1_timeline-content text interview_a1_timeline-content-media">
                      <div className="interview_a1_timeline-detail">17:00</div>
                      <div className="interview_a1_timeline-title">
                        引継ぎ、退勤
                      </div>
                    </div>
                  </div>
                </div>
                <div className="interview_a1_timebox-right">
                  <p className="interview_a1_timebox-title">夜勤</p>
                  <div className="interview_a1_timeline-item">
                    <i className="interview_a1_timeline-axis">
                      <AssetImage
                        src="/assets/images/recruit/interview/time06.svg"
                        alt=""
                        loading="lazy"
                      />
                    </i>
                    <div className="interview_a1_timeline-content text interview_a1_timeline-content-media">
                      <div className="interview_a1_timeline-detail">16:00</div>
                      <div className="interview_a1_timeline-title">
                        出勤、引継ぎ <br />
                        チェックイン業務
                      </div>
                    </div>
                  </div>
                  <div className="interview_a1_timeline-item">
                    <i className="interview_a1_timeline-axis">
                      <AssetImage
                        src="/assets/images/recruit/interview/time07.svg"
                        alt=""
                        loading="lazy"
                      />
                    </i>
                    <div className="interview_a1_timeline-content text interview_a1_timeline-content-media">
                      <div className="interview_a1_timeline-detail">19:00</div>
                      <div className="interview_a1_timeline-title">
                        食事休憩
                      </div>
                    </div>
                  </div>
                  <div className="interview_a1_timeline-item">
                    <i className="interview_a1_timeline-axis">
                      <AssetImage
                        src="/assets/images/recruit/interview/time08.svg"
                        alt=""
                        loading="lazy"
                      />
                    </i>
                    <div className="interview_a1_timeline-content text interview_a1_timeline-content-media">
                      <div className="interview_a1_timeline-detail">20:00</div>
                      <div className="interview_a1_timeline-title">
                        ラウンジ業務
                      </div>
                    </div>
                  </div>
                  <div className="interview_a1_timeline-item">
                    <i className="interview_a1_timeline-axis">
                      <AssetImage
                        src="/assets/images/recruit/interview/time09.svg"
                        alt=""
                        loading="lazy"
                      />
                    </i>
                    <div className="interview_a1_timeline-content text interview_a1_timeline-content-media">
                      <div className="interview_a1_timeline-detail">02:00~</div>
                      <div className="interview_a1_timeline-title">
                        一日の締め作業、シフト作成や報告書作成等の事務作業
                        <br />
                        仮眠
                        <br />
                        当日到着ゲストの準備
                      </div>
                    </div>
                  </div>
                  <div className="interview_a1_timeline-item">
                    <i className="interview_a1_timeline-axis">
                      <AssetImage
                        src="/assets/images/recruit/interview/time10.svg"
                        alt=""
                        loading="lazy"
                      />
                    </i>
                    <div className="interview_a1_timeline-content text interview_a1_timeline-content-media">
                      <div className="interview_a1_timeline-detail">07:00</div>
                      <div className="interview_a1_timeline-title">
                        チェックアウト業務、ラウンジ業務
                      </div>
                    </div>
                  </div>
                  <div className="interview_a1_timeline-item">
                    <i className="interview_a1_timeline-axis">
                      <AssetImage
                        src="/assets/images/recruit/interview/time11.svg"
                        alt=""
                        loading="lazy"
                      />
                    </i>
                    <div className="interview_a1_timeline-content text interview_a1_timeline-content-media">
                      <div className="interview_a1_timeline-detail">09:00</div>
                      <div className="interview_a1_timeline-title">
                        引継ぎ、退勤
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LWrap2>
      </section>

      <section className="l_sect04 recruit-font">
        <LWrap2>
          <div className="swiper swiper_buttons swiper_buttons_media">
            <div className="swiper-button-prev turnPrev"></div>&nbsp;
            <div className="swiper-button-next turnNext"></div>
          </div>
          <div className="new_main">
            <div className="container_main_carouse">
              {isSp ? (
                <>
                  <div className="carouse_right article-media article-swiper-media">
                    <Swiper
                      autoplay={false}
                      loop={false}
                      modules={[Scrollbar, Autoplay, Navigation]}
                      speed={1000}
                      className="c_labelCardSlider"
                      slidesPerView={1}
                      centeredSlides={false}
                      breakpoints={{
                        "768": {
                          spaceBetween: 0,
                        },
                        "769": {
                          spaceBetween: 50,
                        },
                      }}
                      spaceBetween={50}
                      pagination={{
                        clickable: true,
                      }}
                      navigation={{
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                      }}
                    >
                      {carouselData.map((item, index) => {
                        return (
                          <SwiperSlide>
                            <div className="carouse_body">
                              <Link to={item.jumpUrl}>
                                <AssetImage
                                  src={`${item.img.src}`}
                                  alt=""
                                  loading="lazy"
                                />
                                <div className="carouse_title">
                                  {item.title}
                                </div>
                                <div className="carouse_content">
                                  <p>{item.content}</p>
                                </div>
                                <div className="carouse_end">
                                  {item.end_title}
                                </div>
                              </Link>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                </>
              ) : (
                <>
                  <div className="carouse_right article-pc">
                    <Swiper
                      autoplay={false}
                      loop={false}
                      modules={[Scrollbar, Autoplay, Navigation]}
                      speed={1000}
                      className="c_labelCardSlider"
                      slidesPerView={3.5}
                      centeredSlides={false}
                      breakpoints={{
                        "768": {
                          spaceBetween: 0,
                        },
                        "769": {
                          spaceBetween: 50,
                        },
                      }}
                      spaceBetween={50}
                      pagination={{
                        clickable: true,
                      }}
                      navigation={{
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                      }}
                    >
                      {carouselData.map((item, index) => {
                        return (
                          <SwiperSlide>
                            <div className="carouse_body">
                              <Link to={item.jumpUrl}>
                                <AssetImage
                                  src={`${item.img.src}`}
                                  alt=""
                                  loading="lazy"
                                />
                                <div className="carouse_title">
                                  {item.title}
                                </div>
                                <div className="carouse_content">
                                  <p>{item.content}</p>
                                </div>
                                <div className="carouse_end">
                                  {item.end_title}
                                </div>
                              </Link>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="return_overview_class">
            {isSp ? (
              <>
                <Link to="/recruit/interview/" target="_blank">
                  <div className="return_overview_class_internal return-overview-media">
                    <div className="btn-list">一覧に戻る</div>
                    <div className="circle-icon">
                      <img
                        src="/assets/images/recruit/common/icon-new-right.png"
                        alt=""
                      />
                    </div>
                  </div>
                </Link>
              </>
            ) : (
              <>
                <div className="return_overview_class_internal return-overview-media hover_active">
                  一覧に戻る
                  <a href="/recruit/interview/">
                    <img
                      src="/assets/images/recruit/goto.svg"
                      className="return_overview_images"
                    />
                  </a>
                </div>
              </>
            )}
          </div>
        </LWrap2>
      </section>
    </LayoutSl>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
